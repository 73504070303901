<script>
  export default {
    data () {
      let date = new Date()
      let year = date.getFullYear()
      return {
        currentYear: year 
      }
    }
  }
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
           © {{currentYear}} Jebsen Travel
        </div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            <!-- Crafted with
            <i class="mdi mdi-heart text-danger"></i> -->
            Powered by Skywidetech
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>