<script>
import i18n from "../i18n";
import { layoutComputed } from "@/state/helpers";
import { mapGetters,mapActions } from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  components: { },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        // {
        //   flag: require("@/assets/images/flags/china.png"),
        //   language: "zh",
        //   title: "Chinese_Simplified"
        // },
        // {
        //   flag: require("@/assets/images/flags/china_hk.png"),
        //   language: "zhHK",
        //   title: "Chinese_Traditional"
        // },
      ],
      current_language: "en",
      selectedLang: {
        flag: require("@/assets/images/flags/us.jpg"),
        language: "en",
        title: "English"
      },
    };
  },
  computed: {
    ...mapGetters('account', [
      'accountInfo',
      'logedIn',
      'logoutRS'
    ]),
    ...mapGetters('rail', [
      'cartNumber',
    ]),
    ...layoutComputed,
  },
  methods: {
    ...mapActions('account', ['logOutAction']),
    ...mapActions('rail', ['updateCartNumber']),
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toCart(){
      this.$router.push({
        name: 'cart'
      })  
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    setLanguage(localeEntry) {
      this.selectedLang = localeEntry;
      i18n.locale = localeEntry.language;
      this.current_language = i18n.locale;
    },
    logOut () {
      this.logOutAction();
      sessionStorage.removeItem('accountInfo')
      this.$router.push({
        name: 'login'
      })
    },
    toBookingPage () {
      if(this.logedIn){
        this.$router.push({
          name: 'bookingRail'
        })
      }else{
        this.$router.push({
          name: 'login'
        })
      }
    },
    manageAccounts() {
      this.$router.push({
        name: 'accounts'
      })   
    }
  },
  mounted() {
    this.selectedLang = this.languages.find((x) => x.language === i18n.locale);
    // this.text = this.selectedLang.title;
    this.flag = this.selectedLang.flag;

    let cart = []
    if(sessionStorage.getItem('cart') != null && sessionStorage.getItem('cart') != undefined){
        cart = JSON.parse(sessionStorage.getItem('cart'))
    }
    this.updateCartNumber({
        cartNumber: cart.length
    })
  },
  watch: {
    logoutRS: function () {
      this.$nextTick(function () {
        // this.$router.push({
        //   name: 'login'
        // })
      })
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/login" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-dark-jebsen.png" alt height="42" />
            </span>
            <span class="logo-lg">
              <!-- #TODO -->
              <img src="@/assets/images/logo-dark-jebsen.png" alt height="40" />
            </span>
          </a>

          <a href="/login" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light-jebsen.png" alt height="42" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light-jebsen.png" alt height="40" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
          data-toggle="collapse"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>   
		
      <div class="d-flex">
		
		<!-- set locale -->
        <!-- <div class="">
          <b-dropdown variant="white" right toggle-class="header-item">
            <template v-slot:button-content>
              <img
                class=""
                :src="selectedLang.flag"
                alt="Header Language"
                height="16"
              />
            </template>
            <b-dropdown-item
              class="notify-item"
              v-for="(entry, i) in languages"
              :key="`Lang${i}`"
              :value="entry"
              @click="setLanguage(entry)"
              :link-class="{ active: entry.language === current_language }"
            >
              <img
                :src="`${entry.flag}`"
                alt="user-image"
                class="mr-1"
                height="12"
              />
              <span class="align-middle">{{ entry.title }}</span>
            </b-dropdown-item>
          </b-dropdown>     
        </div> -->
		
        <!-- full screen -->
        <!-- <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div> -->

        <!-- cart -->
        <!-- start -->
        <div class="dropdown ml-1">
          <button
          type="button"
          class="btn header-item noti-icon waves-effect"
          @click="toCart"
          >
              <i class="ri-shopping-cart-line text-primary"></i>
          </button>
          <b-badge variant="danger" class="mt-3 ml-n3 rounded-circle position-absolute">{{ cartNumber }}</b-badge>
        </div>
        <!-- end -->

        <!-- booking -->
        <!-- start -->
        <div class="dropdown ml-1">
            <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="toBookingPage"
            >
                <i class="mdi mdi-alpha-b-box-outline text-success"></i>
            </button>
        </div>
        <!-- end -->

        <!-- account -->
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <!-- <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-2.jpg"
              alt="Header Avatar"
            /> -->
            <b-avatar></b-avatar>
            <span class="d-none d-xl-inline-block ml-1">{{
              accountInfo.id
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a class="dropdown-item" @click.prevent="manageAccounts" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.account.accounts") }}
          </a>
          <!-- <a class="dropdown-item d-block" href="#">
            <span class="badge badge-success float-right mt-1">11</span>
            <i class="ri-settings-2-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.kevin.list.settings") }}
          </a>
          <a class="dropdown-item" href="#">
            <i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.kevin.list.lockscreen") }}
          </a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" @click.prevent="logOut" href="#">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="ri-settings-2-line toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>
  </header>
</template>