export const menuItems = [
    {
        id: 1,
        label: 'menuitems.air.text',
        icon: 'ri-flight-takeoff-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.air.badge"
        // },
        link: '/air'
    },
    {
        id: 2,
        label: 'menuitems.hotel.text',
        icon: 'ri-hotel-bed-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.hotel.badge"
        // },
        link: '/hotel'
    },
    {
        id: 3,
        label: 'menuitems.package.text',
        icon: 'ri-gift-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.package.badge"
        // },
        link: '/package'
    },
    {
        id: 4,
        label: 'menuitems.cruise.text',
        icon: 'ri-ship-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.cruise.badge"
        // },
        link: '/cruise'
    },
    {
        id: 5,
        label: 'menuitems.activity.text',
        icon: 'ri-riding-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.activity.badge"
        // },
        link: '/activity'
    },
    {
        id: 6,
        label: 'menuitems.car.text',
        icon: 'ri-roadster-line',
        permission: 'none',
        subItems: [
            {
                id: 7,
                label: 'menuitems.carrental.text',
                icon: 'ri-roadster-line',
                permission: 'none',
                link: '/carrental'
            },
            {
                id: 8,
                label: 'menuitems.cartransfer.text',
                icon: 'ri-taxi-line',
                permission: 'none',
                link: '/cartransfer'
            },
        ]
    },
    {
        id: 9,
        label: 'menuitems.rail.text',
        icon: 'ri-train-line',
        permission: 'access',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.rail.badge"
        // },
        link: '/rail'
    },
    {
        id: 10,
        label: "menuitems.service.text",
        icon: 'ri-visa-line',
        permission: 'none',
        subItems: [
            {
                id: 11,
                label: 'menuitems.insurance.text',
                permission: 'none',
                // icon: 'ri-umbrella-line',
                // badge: {
                //     variant: "success",
                //     text: "menuitems.insurance.badge"
                // },
                link: '/insurance'
            },
            {
                id: 12,
                label: 'menuitems.visapassport.text',
                permission: 'none',
                // icon: 'ri-visa-line',
                // badge: {
                //     variant: "success",
                //     text: "menuitems.visapassport.badge"
                // },
                link: '/visapassport'
            },
        ]
    },
    {
        id: 13,
        label: 'menuitems.holiday.text',
        icon: 'ri-goblet-line',
        permission: 'none',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.holiday.badge"
        // },
        link: '/holiday'
    }
]